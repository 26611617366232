import React from "react";
import Error from "@/components/error";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import { Helmet } from "react-helmet";


const ErrorPage = () => {
    return (
        <MenuContextProvider>
            <Layout PageTitle="Error 404 Page">
                <Helmet>
                    <html lang={"el"} />
                    <title>404 | TOPaper</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>
                <Error />
            </Layout>
        </MenuContextProvider>
    );
};

export default ErrorPage;
